//v1.1
//Master CSS fuer DSTMO TEMPLATE
@import url('https://dstmo.uber.space/data/assets/css/master/dstmo-master.min.css');
//Child CSS fuer Seidel
$dstmoprimcol: #b1c800; //Perimaere Farbe - Info-Leiste Background, nav a:hover, Footer h2 Border
$dstmoseccol: #e2001a;  //Sekundaere Farbe -  5px obere Border, Toogle, ...
$dstmodark: #303030;    //SHK Icons
$dstmolight: #f7f7f7;   //Footer Links

a {
  color: $dstmoprimcol;

  &:hover {
    color: $dstmoseccol;
    text-decoration: underline;
  }
}

.navbar-toggle {
  color: $dstmoseccol;
}

.dstmo-infoleiste {
  background-color: $dstmoprimcol;
  border-top: 5px solid $dstmoseccol;
  color: $dstmolight;
  font-size: 14px;
}

.dstmo-infoleiste a {
  color: $dstmolight;
}

.nav {
  > li > a {
    &:hover {
      background-color: $dstmoprimcol !important;
    }
  }
}

.dstmofooter h2 {
  border-color: $dstmoprimcol;
  color: $dstmolight;
}

.dstmoprimcol {
  color: $dstmoprimcol;

  &:hover {
    color: $dstmoseccol;
  }
}

.dstmoseccol {
  color: $dstmoseccol;
}

.dstmolight {
  color: $dstmolight;

  &:hover {
    color: $dstmoseccol;
  }
}

.dstmoprimcolborder {
  border-color: $dstmoprimcol;
}

.dstmoprimcolbackground {
  background: $dstmoprimcol;
}

.btn {
  background-color: $dstmoprimcol;
  color: $dstmolight;

  &:hover {
    background-color: $dstmoseccol;
    color: $dstmolight;
  }
}

.dstmobtnlight {
  background-color: $dstmoprimcol;
  color: $dstmolight;

  &:hover {
    background-color: $dstmolight;
    color: $dstmoprimcol;
  }
}

.dstmoseccolicon i {
  &:hover {
    color: $dstmoseccol;
  }
}

.ds-leica-icon a {
  color: $dstmodark;

  &:hover {
    color: $dstmoseccol;
  }
}
// Highlight Icon

.dstmohighlighticon {
  background: $dstmoprimcol;
  color: $dstmolight;
}
//Social Icons in der Beitragsuebersicht

.dstmosocialicons {
  a {
    &:hover {
      background-color: unset;
      color: $dstmolight;
    }
  }

  i {
    &:hover {
      background-color: $dstmoprimcol;
      color: $dstmolight;
    }
  }
}
